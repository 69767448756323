import React from "react";
import HomePage from "../Pages/HomePage/home-page";
import AboutPage from "../Pages/AboutPage/about-page";
import SkilsPage from "../Pages/SkillsPage/skills-page";
import ProjectPage from "../Pages/ProjectPage/project-page";
import ContactPage from "../Pages/ContactPage/contact-page";

type BaseAppType = {
  menu: Menu;
  setMenu: (menu: Menu) => void;
};

const BaseApp = ({ menu, setMenu }: BaseAppType) => {
  if (menu === "Home") return <HomePage></HomePage>;
  if (menu === "About") return <AboutPage></AboutPage>;
  if (menu === "Skills") return <SkilsPage></SkilsPage>;
  if (menu === "Project") return <ProjectPage></ProjectPage>;
  if (menu === "Contact") return <ContactPage></ContactPage>;
  return null;
};

export default BaseApp;
