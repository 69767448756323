import React, { useContext } from "react";
import ParticalDotLine from "../../Components/partical-dot-line";
import { Button, Container, Grid } from "@material-ui/core";
import { Typography } from "@material-ui/core";
import HomeBanner from "../../Images/home_banner.svg";
import { MenuContext } from "../../Contexts/menu-context";
const HomePage = () => {
  const { setMenu } = useContext(MenuContext);
  return (
    <React.Fragment>
      <ParticalDotLine></ParticalDotLine>
      <Grid container>
        <Grid item md={6} sm={12} xs={12}>
          <Container style={{ marginTop: "100px", padding: "40px" }}>
            <Typography variant="h2" align="center" style={{ color: "#ffff" }}>
              👋🏻 Hey...
            </Typography>
            <Typography variant="h2" align="center" style={{ color: "#ffff" }}>
              I'm Ravi Raval
            </Typography>
            <Typography
              variant="h5"
              align="center"
              style={{
                textAlign: "justify",
                marginBottom: "10px",
                color: "#ffff",
              }}
            >
              A passionate Software Developer 🚀 having an experience of
              building Web applications and Desktop Application with JavaScript
              / Reactjs / Electronjs / couchDB and some other cool libraries and
              frameworks.
            </Typography>
            <Button
              variant="contained"
              color="primary"
              onClick={() => setMenu("Contact")}
            >
              Conatct Me
            </Button>
          </Container>
        </Grid>
        <Grid item md={6} sm={12} xs={12}>
          <img src={HomeBanner} title="test" alt="Home Page Banner" />
        </Grid>
      </Grid>
    </React.Fragment>
  );
};

export default HomePage;
