import React from "react";
import MainHeader from "../../Components/Header/header";

type NavigationPropType = {
  children: React.ReactNode;
};

const NavigationMenu = ({ children }: NavigationPropType) => {
  return (
    <React.Fragment>
      <MainHeader></MainHeader>
      {children}
    </React.Fragment>
  );
};

export default NavigationMenu;
