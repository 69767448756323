import React from "react";
import Accordion from "@material-ui/core/Accordion";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { Typography } from "@material-ui/core";

const Eductions = () => {
  const [expanded, setExpanded] = React.useState({
    first: false,
    secound: false,
    third: false,
    fourth: false,
  });

  return (
    <React.Fragment>
      <Accordion
        expanded={expanded.first}
        onClick={() =>
          setExpanded({
            secound: false,
            third: false,
            fourth: false,
            first: !expanded.first,
          })
        }
      >
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography variant="h6">M.sc Computer Science</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <ul>
            <li>Mithibai College Of Science and Arts</li>
            <li>Mumbai University</li>
            <li>2019 - 2021</li>
            <li>CGPA :7.8</li>
          </ul>
        </AccordionDetails>
      </Accordion>
      <Accordion
        expanded={expanded.secound}
        onClick={() =>
          setExpanded({
            first: false,
            secound: !expanded.secound,
            third: false,
            fourth: false,
          })
        }
      >
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography variant="h6">B.sc Information Technology</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <ul>
            <li>Usha Pravin Gandhi College Of Management</li>
            <li>Mumbai University</li>
            <li>2016 - 2019</li>
            <li>CGPA :8.7</li>
          </ul>
        </AccordionDetails>
      </Accordion>
      <Accordion
        expanded={expanded.third}
        onClick={() =>
          setExpanded({
            first: false,
            secound: false,
            third: !expanded.third,
            fourth: false,
          })
        }
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1bh-content"
          id="panel1bh-header"
        >
          <Typography>HSC</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <ul>
            <li>Mithibai College Of Science and Atrs</li>
            <li>Mumbai University</li>
            <li>2014 - 2016 </li>
            <li>Percentage: 65% </li>
          </ul>
        </AccordionDetails>
      </Accordion>
      <Accordion
        expanded={expanded.fourth}
        onClick={() =>
          setExpanded({
            first: false,
            secound: false,
            third: false,
            fourth: !expanded.fourth,
          })
        }
      >
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography>SSC</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <ul>
            <li>J.H.Poddar High School</li>
            <li>Mumbai University</li>
            <li>March 2014</li>
            <li>Percentage: 89.80%</li>
          </ul>
        </AccordionDetails>
      </Accordion>
    </React.Fragment>
  );
};

export default Eductions;
