import { Button, CardActions } from "@material-ui/core";
import { Card, CardContent, CardMedia, Typography } from "@material-ui/core";
import React from "react";

type ProjectCardType = {
  imgURL: string;
  imgAlt: string;
  projectName: string;
  projectDesc: string;
  demoLink: string;
};

const ProjectCard = ({
  demoLink,
  imgAlt,
  imgURL,
  projectDesc,
  projectName,
}: ProjectCardType) => {
  return (
    <React.Fragment>
      <Card
        style={{
          backgroundColor: "#D4D4D4",
          alignItems: "stretch",
          height: "380px",
        }}
      >
        <CardMedia
          component="img"
          alt={imgAlt}
          height="140"
          src={imgURL}
          // image="/static/images/cards/contemplative-reptile.jpg"
          title="Contemplative Reptile"
        />

        <CardContent>
          <Typography variant="h6">{projectName}</Typography>
          <Typography variant="body2" style={{ marginTop: "10px" }}>
            {projectDesc}
          </Typography>
        </CardContent>
        <CardActions>
          <a
            href={demoLink}
            target="_blank"
            style={{ textDecoration: "none" }}
            rel="noreferrer"
          >
            <Button variant="contained" color="primary">
              Demo
            </Button>
          </a>
        </CardActions>
      </Card>
    </React.Fragment>
  );
};

export default ProjectCard;
