import { Container, Grid, Typography } from "@material-ui/core";
import AboutItem from "../../Components/AboutItem/about-item";
import ParticalDotLine from "../../Components/partical-dot-line";
import Eduction from "../../Components/Eduction/eduction";
import Experiance from "../../Components/Experiance/experiance";
import AboutBanner from "../../Images/about_banner.svg";
import React from "react";

import "../../App.css";
const AboutPage = () => {
  return (
    <React.Fragment>
      <ParticalDotLine></ParticalDotLine>
      <Container style={{ padding: "30px" }}>
        <Grid container>
          <Grid item md={6} sm={12} xs={12}>
            <img
              src={AboutBanner}
              alt="About Page Banner"
              style={{ width: "60%", margin: "30px", padding: "40px" }}
            />
          </Grid>
          <Grid item md={6} sm={12} xs={12} justifyContent="center">
            <p className="about-title">Little About Me</p>
            <div>
              <AboutItem text="🏡 I live in Bhayander, Maharashtra IN."></AboutItem>
              <AboutItem text="👨‍👩‍👦‍👦 I belong to a family of four."></AboutItem>
              <AboutItem text="🤗 When I'm free; I like to explore startups, watch ted talks, scratch ideas and talk with some good vibing humans."></AboutItem>
              <AboutItem text="❤️ I love to play Music(harmonium, Guitar) a lot and also to binge watch amazing suspenseful and sci-fi web series and movies."></AboutItem>
              <AboutItem text="🤩 I like watching amazing art, listening songs and reading self help books."></AboutItem>
            </div>
          </Grid>
        </Grid>
        <div style={{ backgroundColor: "#444444", padding: "30px" }}>
          <Typography align="center" variant="h4">
            My Timeline
          </Typography>

          <Grid container spacing={3}>
            <Grid item md={6} sm={12} xs={12}>
              <Typography align="center" variant="h5">
                Education
              </Typography>
              <Eduction></Eduction>
            </Grid>
            <Grid item md={6} sm={12} xs={12}>
              <Typography align="center" variant="h5">
                Experience
              </Typography>
              <Experiance></Experiance>
            </Grid>
          </Grid>
        </div>
      </Container>
    </React.Fragment>
  );
};

export default AboutPage;
