import React, { useState } from "react";
import BaseApp from "./BaseApp/base-app";
import { MenuContext } from "./Contexts/menu-context";
import NavigationMenu from "./HOC/NavigationMenu/navigation-menu";
function App() {
  const [menu, setMenu] = useState<Menu>("Home");
  return (
    <React.Fragment>
      <MenuContext.Provider
        value={{ menu: menu, setMenu: (menu: Menu) => setMenu(menu) }}
      >
        <NavigationMenu>
          <BaseApp menu={menu} setMenu={(menu: Menu) => setMenu(menu)} />
        </NavigationMenu>
      </MenuContext.Provider>
    </React.Fragment>
  );
}

export default App;
