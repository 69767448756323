import { Container, Grid, Typography } from "@material-ui/core";
import ParticalDotLine from "../../Components/partical-dot-line";
import JavascriptPNg from "../../Images/JavascriptSvg.png";
import ReactPNG from "../../Images/ReactPNG.png";
import ReduxPng from "../../Images/ReduxPng.png";
import MaterialUIPng from "../../Images/MaterialUIPng.svg";
import SementicUIPNG from "../../Images/SementicUi.png";
import ElectronJsPng from "../../Images/electronjs.png";
import NodejsPng from "../../Images/NodeJsPng.png";
import AngulerPng from "../../Images/AngulerPng.png";
import VueJsPng from "../../Images/VueJs Png.png";
import MysqlPng from "../../Images/MysqlPng.png";
import WebPack from "../../Images/WebPack.png";
import VsCode from "../../Images/VsCode.png";
import WordpressPng from "../../Images/Wordpress.png";
import RESTApiPng from "../../Images/RestApi.png";
import GraphQLPng from "../../Images/GraphQl.png";
import CouchDbPng from "../../Images/CouchDb.png";
import GatbyPng from "../../Images/gatsby.png";

import React from "react";

const SkillsPage = () => {
  return (
    <React.Fragment>
      <ParticalDotLine></ParticalDotLine>
      <div style={{ padding: "10px" }}>
        <Grid container style={{ margin: "10px" }}>
          <Grid item md={12}>
            <Container>
              <Typography
                variant="h5"
                align="center"
                style={{ color: "#ffff" }}
              >
                Skills
              </Typography>
              <Grid container>
                <Grid item md={2} sm={6} xs={6}>
                  <img
                    src={JavascriptPNg}
                    className="skills_image"
                    alt="Javascript"
                  />
                </Grid>
                <Grid
                  item
                  md={2}
                  sm={6}
                  xs={6}
                  alignItems="center"
                  justifyContent="center"
                >
                  <img src={ReactPNG} className="skills_image" alt="React Js" />
                </Grid>
                <Grid item md={2} sm={6} xs={6}>
                  <img
                    src={ReduxPng}
                    alt="Redux Png"
                    className="skills_image"
                  />
                </Grid>
                <Grid item md={2} sm={6} xs={6}>
                  <img
                    src={MaterialUIPng}
                    className="skills_image"
                    alt="Material UIPng"
                  />
                </Grid>
                <Grid item md={2} sm={6} xs={6}>
                  <img
                    src={SementicUIPNG}
                    alt="Sementic UI Png"
                    className="skills_image"
                  />
                </Grid>
                <Grid item md={2} sm={6} xs={6}>
                  <img
                    src={ElectronJsPng}
                    className="skills_image"
                    alt="ElectronJs Png"
                  />
                </Grid>
              </Grid>
              <div style={{ marginTop: "50px" }}>
                <Typography
                  variant="h5"
                  align="center"
                  style={{ color: "#ffff" }}
                >
                  Additional Awareness
                </Typography>
                <Grid container style={{ marginTop: "40px" }}>
                  <Grid item md={2} sm={6} xs={6}>
                    <img src={NodejsPng} width="70%" alt="Nodejs Png" />
                  </Grid>
                  <Grid item md={2} sm={6} xs={6}>
                    <img src={AngulerPng} alt="AngulerJs Png" width="60%" />
                  </Grid>
                  <Grid item md={2} sm={6} xs={6}>
                    <img src={VueJsPng} alt="VueJs Png" width="60%" />
                  </Grid>
                  <Grid item md={2} sm={6} xs={6}>
                    <img src={MysqlPng} alt="Mysql Png" width="90%" />
                  </Grid>
                  <Grid item md={2} sm={6} xs={6}>
                    <img src={WebPack} alt="WebpackPng" width="70%" />
                  </Grid>
                  <Grid item md={2} sm={6} xs={6}>
                    <img src={VsCode} width="80%" alt="VsCodePng" />
                  </Grid>
                </Grid>
                <Grid container style={{ marginTop: "30px" }}>
                  <Grid item md={2} sm={6} xs={6}>
                    <img
                      src={WordpressPng}
                      className="skills_image"
                      alt="Wordpress Png"
                    />
                  </Grid>
                  <Grid item md={2} sm={6} xs={6}>
                    <img
                      src={RESTApiPng}
                      alt="RestApi Png"
                      className="skills_image"
                    />
                  </Grid>
                  <Grid item md={2} sm={6} xs={6}>
                    <img
                      src={GraphQLPng}
                      alt="GraphQl Png"
                      className="skills_image"
                    />
                  </Grid>
                  <Grid item md={2} sm={6} xs={6}>
                    <img
                      src={GatbyPng}
                      alt="Gatsby Png"
                      className="skills_image"
                    />
                  </Grid>

                  <Grid item md={2} sm={6} xs={6}>
                    <img
                      src={CouchDbPng}
                      alt="CouchDb"
                      className="skills_image"
                    />
                  </Grid>
                </Grid>
              </div>
            </Container>
          </Grid>
        </Grid>
      </div>
    </React.Fragment>
  );
};

export default SkillsPage;
