import {
  Grid,
  Typography,
  Button,
  TextField,
  Container,
} from "@material-ui/core";
import ParticalBubbleLine from "../../Components/partical-bubble";
import React from "react";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import gamiluri from "../../Images/gmail.png";
import LinkdinPng from "../../Images/linkdin.png";
import GithubPng from "../../Images/Github.png";
import YoutubePng from "../../Images/youtube.png";
import TweeterPng from "../../Images/tweeter.png";
import HeackerRankPng from "../../Images/heackerRankPng.png";
import CogoMessage from "../../Components/SuccessCogo/success-cogo";

type MessageType = {
  name: string;
  email: string;
  phone?: string;
  message: string;
};

function validateEmail(email: string) {
  const re =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
}

const ContactPage = () => {
  const [isContactForm, setisContactForm] = React.useState(false);
  const [message, setMessage] = React.useState<MessageType>({
    name: "",
    email: "",
    message: "",
  });

  const [messErr, setMessErr] = React.useState<MessageType>({
    name: "",
    email: "",
    message: "",
  });

  const [sanckOpen, setSanckOpen] = React.useState(false);
  // let templateParams = {
  //   from_name: "rrdev.js@gmail.com",
  //   to_name: "<ravalravi103@gmail.com>",
  //   subject: "Developement Test",
  //   message_html: "Hello how are you ??",
  // };

  // const data = {
  //   service_id: "service_mpsf7ht",
  //   template_id: "template_ga1yw99",
  //   user_id: "user_ZwndZWsOu9gXYoDH2Wykr",
  //   template_params: templateParams,
  // };

  // const send = () => {
  //   fetch("https://api.emailjs.com/api/v1.0/email/send", {
  //     method: "POST",
  //     body: JSON.stringify(data),
  //     mode: "no-cors",
  //     headers: {
  //       "Content-Type": "application/json",
  //     },
  //   })
  //     .then((data) => console.log(data))
  //     .then((data) => console.log(data))
  //     .catch((err) => console.log(err));
  // };

  const handleSubmit = () => {
    if (!message.name) {
      setMessErr({
        ...messErr,
        name: "Name Required!",
      });

      return;
    }
    if (!message.email) {
      setMessErr({ ...messErr, name: "", email: "Email Required!" });
      return;
    }

    if (!validateEmail(message.email)) {
      setMessErr({ ...messErr, name: "", email: "Invalid Email!" });
      return;
    }

    if (message.phone) {
      const mess = validatePhone(message.phone);
      setMessErr({ ...messErr, name: "", email: "", phone: mess as string });
      return;
    }

    if (!message.message) {
      setMessErr({
        ...messErr,
        name: "",
        email: "",
        message: "Message Required!",
      });
      return;
    }
    setSanckOpen(true);
    handleReset();
  };

  const handleReset = () => {
    setMessage({ name: "", email: "", message: "", phone: "" });
  };

  const validatePhone = (phone: any) => {
    if (isNaN(phone)) return "Only Number Required!";
    if (phone.length !== 10) return "Invalid Number";
    return false;
  };

  const checkFromValidation = () => {
    return (
      <React.Fragment>
        <CogoMessage
          open={messErr.name ? true : false}
          onClose={() =>
            setMessErr({ ...message, name: "", email: "", message: "" })
          }
          severity="error"
          message={messErr.name}
        ></CogoMessage>
        <CogoMessage
          open={messErr.email ? true : false}
          onClose={() => setMessErr({ ...message, name: "", email: "" })}
          severity="error"
          message={messErr.email}
        ></CogoMessage>
        <CogoMessage
          open={messErr.phone ? true : false}
          onClose={() =>
            setMessErr({ ...message, name: "", email: "", phone: "" })
          }
          severity="error"
          message={messErr.phone as string}
        ></CogoMessage>
        <CogoMessage
          open={messErr.message ? true : false}
          onClose={() => setMessErr({ ...message, email: "", message: "" })}
          severity="error"
          message={messErr.message}
        ></CogoMessage>
      </React.Fragment>
    );
  };

  return (
    <React.Fragment>
      <ParticalBubbleLine></ParticalBubbleLine>
      {/* <CogoMessage
          message="Email Sent Successfully!"
          open={sanckOpen}
          onClose={() => setSanckOpen(false)}
          severity="success"
        ></CogoMessage> */}
      {checkFromValidation()}
      {isContactForm ? (
        <React.Fragment>
          <Container style={{ padding: "60px" }}>
            <Card style={{ backgroundColor: "#D4D4D4" }}>
              <CardContent>
                <Typography variant="h4" align="center">
                  Contact Me!
                </Typography>
                <form style={{ marginTop: "20px" }}>
                  <TextField
                    variant="outlined"
                    placeholder="Name"
                    required
                    label="Name"
                    value={message.name}
                    style={{ marginTop: "20px" }}
                    onChange={(event) =>
                      setMessage({ ...message, name: event.target.value })
                    }
                    fullWidth
                  ></TextField>
                  <TextField
                    variant="outlined"
                    placeholder="Email"
                    label="Email"
                    value={message.email}
                    onChange={(e) =>
                      setMessage({ ...message, email: e.target.value })
                    }
                    className="form-control"
                    style={{ marginTop: "20px" }}
                    required
                    fullWidth
                  ></TextField>
                  <TextField
                    variant="outlined"
                    placeholder="Phone Number"
                    value={message.phone}
                    onChange={(e) =>
                      setMessage({ ...message, phone: e.target.value })
                    }
                    style={{ marginTop: "20px" }}
                    label="Phone Number"
                    fullWidth
                  ></TextField>
                  <TextField
                    variant="outlined"
                    placeholder="Message"
                    value={message.message}
                    onChange={(e) =>
                      setMessage({ ...message, message: e.target.value })
                    }
                    style={{ marginTop: "20px" }}
                    required
                    label="Message"
                    fullWidth
                  ></TextField>
                  <div style={{ marginTop: "20px" }}>
                    <Button
                      color="secondary"
                      variant="contained"
                      onClick={() => handleSubmit()}
                    >
                      Submit
                    </Button>
                    <Button
                      style={{ marginLeft: "10px" }}
                      color="secondary"
                      onClick={() => handleReset()}
                      variant="contained"
                    >
                      Reset
                    </Button>
                  </div>
                </form>
              </CardContent>
            </Card>
            <Button
              variant="contained"
              color="primary"
              style={{ margin: "20px 0px" }}
              onClick={() => setisContactForm(false)}
            >
              Social Hanlde?
            </Button>
          </Container>
        </React.Fragment>
      ) : (
        <Grid container>
          <Grid item md={12} sm={12} xs={12}>
            <Container style={{ marginTop: "50px", padding: "50px" }}>
              <Typography variant="h2" style={{ color: "#ffff" }}>
                Connect with me On My Social Handle ?
              </Typography>

              <Grid container spacing={3} style={{ marginTop: "40px" }}>
                <Grid item md={2} sm={4} xs={6}>
                  <a
                    href="mailto:rrdev.js@gmail.com"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <img src={gamiluri} className="social_image" alt="Gmail" />
                  </a>
                </Grid>
                <Grid item md={2} sm={4} xs={6}>
                  <a
                    href="https://www.linkedin.com/in/ravi-raval-69b106188/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <img
                      src={LinkdinPng}
                      className="social_image"
                      alt="Linkdin"
                    />
                  </a>
                </Grid>
                <Grid item md={2} sm={4} xs={6}>
                  <a
                    href="https://github.com/ravalravi103"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <img
                      src={GithubPng}
                      className="social_image"
                      alt="gitHub"
                    />
                  </a>
                </Grid>
                <Grid item md={2} sm={4} xs={6}>
                  <a
                    href="https://www.youtube.com/XplosiveWebtech"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <img
                      src={YoutubePng}
                      alt="Youtube"
                      style={{
                        width: "70%",
                        marginTop: "10px",
                        border: "10px solid #ffffff",
                        padding: "5px",
                      }}
                    />
                  </a>
                </Grid>
                <Grid item md={2} sm={4} xs={6}>
                  <a
                    href="https://twitter.com/RavalRa43740126"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <img
                      src={TweeterPng}
                      className="social_image"
                      alt="Tweeter"
                    />
                  </a>
                </Grid>
                <Grid item md={2} sm={4} xs={6}>
                  <a
                    href="https://www.hackerrank.com/ravalravi103"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <img
                      src={HeackerRankPng}
                      className="social_image"
                      alt="HeackerRank"
                    />
                  </a>
                </Grid>
              </Grid>
              <Typography align="center" style={{ padding: "50px" }}>
                <Button
                  variant="contained"
                  color="secondary"
                  onClick={() => setisContactForm(true)}
                >
                  Contact Form
                </Button>
              </Typography>
            </Container>
          </Grid>
        </Grid>
      )}
    </React.Fragment>
  );
};

export default ContactPage;
