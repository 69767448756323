import {
  Button,
  Divider,
  Drawer,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
} from "@material-ui/core";
import { useState, useContext } from "react";
import React from "react";

// Icon
import HomeIcon from "@material-ui/icons/Home";
import MenuIcon from "@material-ui/icons/Menu";
import ContactMailIcon from "@material-ui/icons/ContactMail";
import PersonIcon from "@material-ui/icons/Person";
import MenuBookIcon from "@material-ui/icons/MenuBook";
import AddToQueueIcon from "@material-ui/icons/AddToQueue";

import { MenuContext } from "../../Contexts/menu-context";
const navlinks = [
  { text: "Home", icon: <HomeIcon /> },
  { text: "About", icon: <PersonIcon /> },
  { text: "Skills", icon: <MenuBookIcon /> },
  { text: "Project", icon: <AddToQueueIcon /> },
  { text: "Contact", icon: <ContactMailIcon /> },
];

const MainHeader = () => {
  const [isDrawerOpen, setisDrawerOpen] = useState(false);
  const { setMenu } = useContext(MenuContext);
  return (
    <React.Fragment>
      <Button
        color="secondary"
        size="large"
        style={{ position: "fixed", top: 0, left: 0 }}
        onClick={() => setisDrawerOpen(true)}
      >
        <MenuIcon />
      </Button>
      <Drawer
        anchor="left"
        open={isDrawerOpen}
        onClose={() => setisDrawerOpen(false)}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            flex: 1,
          }}
        >
          <span style={{ flex: 0.2, padding: 0, margin: 0 }}>
            <h2
              style={{ cursor: "pointer" }}
              onClick={() => {
                setisDrawerOpen(false);
                setMenu("Home");
              }}
            >
              Raval Ravi
            </h2>
          </span>
          <List style={{ flex: 0.7 }}>
            {navlinks.map((link, index) => (
              <ListItem
                button
                style={{ width: 200 }}
                key={link.text}
                onClick={() => {
                  setMenu(link.text as Menu);
                  setisDrawerOpen(false);
                }}
              >
                <ListItemIcon>{link.icon}</ListItemIcon>
                <ListItemText primary={link.text} />
              </ListItem>
            ))}
          </List>
          <span style={{ flex: 0.1 }}>Created By @RaviRaval</span>
        </div>
        <Divider />
      </Drawer>
      <Button
        color="primary"
        variant="outlined"
        style={{ position: "fixed", top: 10, right: 10 }}
        onClick={() => {
          //  Downlord Resume
          console.log("Downlord Resume");
        }}
      >
        Resume
      </Button>
    </React.Fragment>
  );
};

export default MainHeader;
