import React from "react";
import Accordion from "@material-ui/core/Accordion";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { Typography } from "@material-ui/core";

const Experiance = () => {
  const [expanded, setExpanded] = React.useState({
    first: false,
    secound: false,
    third: false,
    fourth: false,
  });

  return (
    <React.Fragment>
      <Accordion
        expanded={expanded.first}
        onClick={() =>
          setExpanded({
            secound: false,
            third: false,
            fourth: false,
            first: !expanded.first,
          })
        }
      >
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography variant="h6">
            Libratherm Instrument PVT.LTD
            <p className="accordianFont">March 2021 – Current</p>
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography variant="body1">
            Software Development Using Javascript, TypeScript, ReactJs,
            ElectronJs ,Pc Software for Embedded Device which is Mainly used for
            Measure Tempreture, Humidity, Pressure Using The Modebus protocal
            and MQTT Protocall
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion
        expanded={expanded.secound}
        onClick={() =>
          setExpanded({
            first: false,
            secound: !expanded.secound,
            third: false,
            fourth: false,
          })
        }
      >
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography variant="h6">
            Deuex solutions
            <p className="accordianFont">October 2020 - February 2021</p>
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            Frontend Developement Using ReactJs, Javascript and Other Web
            Technology
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion
        expanded={expanded.third}
        onClick={() =>
          setExpanded({
            first: false,
            secound: false,
            third: !expanded.third,
            fourth: false,
          })
        }
      >
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography>
            Tuscan Touch Web Developement
            <p className="accordianFont">June 2019 - July 2019</p>
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            Static and Dynamic Business Website Development Using HTML, CSS,
            Javascript, JQuery, Wordpress
          </Typography>
        </AccordionDetails>
      </Accordion>
    </React.Fragment>
  );
};

export default Experiance;
