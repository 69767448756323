import React from "react";

type AboutItemType = {
  text: string;
};

const AboutItem = ({ text }: AboutItemType) => {
  return (
    <React.Fragment>
      <p className="font-style">{text}</p>
    </React.Fragment>
  );
};

export default AboutItem;
