import React from "react";

import MuiAlert from "@material-ui/lab/Alert";
import Snackbar from "@material-ui/core/Snackbar";

function Alert(props: any) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

type SeverityType = "error" | "info" | "success" | "warning";

type SuccessCogoType = {
  open: boolean;
  onClose: () => void;
  message: string;
  severity: SeverityType;
};

const CogoMessage = ({ open, onClose, message, severity }: SuccessCogoType) => {
  console.log(message);
  return (
    <React.Fragment>
      <Snackbar open={open} autoHideDuration={6000} onClose={() => onClose()}>
        <Alert onClose={() => onClose()} severity={severity}>
          {message}
        </Alert>
      </Snackbar>
    </React.Fragment>
  );
};

export default CogoMessage;
