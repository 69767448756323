import { Container, Grid } from "@material-ui/core";
import React from "react";
import { projectList } from "./project-uitls";
import ParticalDotLine from "../../Components/partical-dot-line";

import ProjectCard from "../../Components/ProjectCard/project-card";
const ProjectPage = () => {
  return (
    <React.Fragment>
      <ParticalDotLine></ParticalDotLine>
      <Container style={{ marginTop: "50px" }}>
        <Grid container spacing={3}>
          {projectList.map((project) => {
            return (
              <Grid item md={4}>
                <ProjectCard
                  key={project.name}
                  demoLink={project.demoLink}
                  imgAlt={project.alt}
                  imgURL={project.imgLink}
                  projectDesc={project.desc}
                  projectName={project.name}
                ></ProjectCard>
              </Grid>
            );
          })}
        </Grid>
      </Container>
    </React.Fragment>
  );
};

export default ProjectPage;
